import React, { useState, useEffect } from 'react';
import packageJson from '../package.json';
import { fullLoadingWithText } from './components/molecules/page/PageLoader';

const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (let i = 0; i < names.length; i += 1) {
        caches.delete(names[i]);
      }
    });
  }
  // delete browser cache and hard reload
  window.location.reload();
};

function withClearCache(Component: React.FC) {
  function ClearCacheComponent(props: any) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      fetch('/meta.json')
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          if (latestVersionDate > currentVersionDate) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else setIsLatestBuildDate(true);
        })
        .catch(() => {
          setIsLatestBuildDate(true);
        });
    }, []);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return isLatestBuildDate ? <Component {...props} /> : fullLoadingWithText('Downloading Latest Version');
  }

  return ClearCacheComponent;
}

export default withClearCache;
