import { SimulationGuide, SimulationSetting } from '../../../../../schemas/admin';
import { Geo, GeoLoc } from '../../../../../schemas/match';
import { distanceGeoInKM } from '../../../utils/math';
import { AdminAction } from '../action/admin';

export type GuideSimulationStatus =
  | 'idle'
  | 'target_added'
  | 'expanding_radius'
  | 'measure_distance'
  | 'eliminating_candidate'
  | 'done';

export type GuideSimulationV1Status = 'idle' | 'target_added' | 'measure_distance' | 'done';

export const initialState = {
  guideSimulation: {
    number: 0,
    list: [] as SimulationGuide[],
    destination: undefined as GeoLoc | undefined,
    setting: {
      stepDelay: 3, // in Seconds
      initialRadius: 30, // in KM
      incrementalRadius: 15, // in KM
      maximumRadius: 70, // in KM
      minimumCandidate: 3,
    } as SimulationSetting,
    state: {
      isReturnExpectedResult: false,
      simulationStatus: 'idle' as GuideSimulationStatus,
      candidateList: [] as SimulationGuide[],
      previousRadius: 0,
      currentRadius: 0,
      currentCenter: {
        lat: -8.65,
        lng: 115.216667,
      } as Geo,
    },
    isRealGuide: false,
  },
  guideSimulationV1: {
    number: 0,
    list: [] as SimulationGuide[],
    destination: undefined as GeoLoc | undefined,
    state: {
      match: undefined as SimulationGuide | undefined,
      simulationStatus: 'idle' as GuideSimulationV1Status,
      candidateList: [] as SimulationGuide[],
      currentCenter: {
        lat: -8.65,
        lng: 115.216667,
      } as Geo,
    },
    setting: {
      stepDelay: 3, // in Seconds
      maximumRadius: 70, // in KM
    },
    isRealGuide: false,
  },
  matchingSetting: {
    initialRadius: 30, // in KM
    incrementalRadius: 15, // in KM
    maximumRadius: 70, // in KM
    minimumCandidate: 3,
  },
};

export type AdminState = typeof initialState;

export default (state = initialState, action: AdminAction): AdminState => {
  switch (action.type) {
    case 'IMPORT_GUIDE_SIMULATION':
      return {
        ...state,
        guideSimulation: {
          ...state.guideSimulation,
          list: action.guides.map((guide, no) => ({ ...guide, number: no + 1 })),
          state: {
            ...state.guideSimulation.state,
            currentCenter: action.guides.length
              ? action.guides[action.guides.length - 1].location.geo
              : state.guideSimulation.state.currentCenter,
          },
          isRealGuide: true,
        },
      };
    case 'IMPORT_GUIDE_SIMULATION_V1':
      return {
        ...state,
        guideSimulationV1: {
          ...state.guideSimulationV1,
          list: action.guides.map((guide, no) => ({ ...guide, number: no + 1 })),
          state: {
            ...state.guideSimulationV1.state,
            currentCenter: action.guides.length
              ? action.guides[action.guides.length - 1].location.geo
              : state.guideSimulationV1.state.currentCenter,
          },
          isRealGuide: true,
        },
      };
    case 'SETTING_MATCH_DEFAULT':
      return {
        ...state,
        matchingSetting: action.setting,
      };
    case 'RESET_GUIDE_SIMULATION':
      return {
        ...state,
        guideSimulation: {
          ...initialState.guideSimulation,
        },
      };
    case 'STOP_GUIDE_SIMULATION':
      return {
        ...state,
        guideSimulation: {
          ...state.guideSimulation,
          destination: undefined,
          list: state.guideSimulation.list.map((item) => ({ ...item, status: 'idle', distance: undefined })),
          state: {
            ...initialState.guideSimulation.state,
            simulationStatus: 'idle',
            currentCenter: state.guideSimulation.state.currentCenter,
          },
        },
      };
    case 'REMOVE_GUIDE_SIMULATION':
      return {
        ...state,
        guideSimulation: {
          ...state.guideSimulation,
          list: state.guideSimulation.list.filter((item) => item.number !== action.number),
        },
      };
    case 'ADD_GUIDE_SIMULATION':
      return {
        ...state,
        guideSimulation: {
          ...state.guideSimulation,
          number: state.guideSimulation.number + 1,
          list: [
            ...state.guideSimulation.list,
            {
              ...action.guide,
              number: state.guideSimulation.number + 1,
            },
          ],
          state: {
            ...state.guideSimulation.state,
            currentCenter: action.guide.location.geo,
          },
        },
      };
    case 'DESTINATION_GUIDE_SIMULATION':
      return {
        ...state,
        guideSimulation: {
          ...state.guideSimulation,
          list: state.guideSimulation.list.map((item) => ({
            ...item,
            status: 'in selection',
          })),
          destination: action.destination,
          state: {
            ...state.guideSimulation.state,
            simulationStatus: 'target_added',
            currentCenter: action.destination.geo,
          },
        },
      };
    case 'STATUS_GUIDE_SIMULATION':
      if (state.guideSimulation.state.simulationStatus !== 'idle') {
        switch (action.status) {
          case 'done':
            return {
              ...state,
              guideSimulation: {
                ...state.guideSimulation,
                list: state.guideSimulation.list.map((item) => ({
                  ...item,
                  status: item.status === 'candidate' ? 'selected' : item.status,
                })),
                state: {
                  ...state.guideSimulation.state,
                  simulationStatus: action.status,
                  isReturnExpectedResult:
                    state.guideSimulation.state.candidateList.length >= state.guideSimulation.setting.minimumCandidate,
                },
              },
            };
          case 'expanding_radius':
            return {
              ...state,
              guideSimulation: {
                ...state.guideSimulation,
                state: {
                  ...state.guideSimulation.state,
                  simulationStatus: action.status,
                  previousRadius: state.guideSimulation.state.currentRadius,
                  currentRadius:
                    state.guideSimulation.state.currentRadius +
                    (state.guideSimulation.state.currentRadius === 0
                      ? state.guideSimulation.setting.initialRadius
                      : (state.guideSimulation.state.currentRadius + state.guideSimulation.setting.incrementalRadius >
                          state.guideSimulation.setting.maximumRadius &&
                          state.guideSimulation.setting.maximumRadius - state.guideSimulation.state.currentRadius) ||
                        state.guideSimulation.setting.incrementalRadius),
                },
              },
            };
          case 'measure_distance':
            return {
              ...state,
              guideSimulation: {
                ...state.guideSimulation,
                list: state.guideSimulation.list.map((item) => ({
                  ...item,
                  distance: state.guideSimulation.destination
                    ? distanceGeoInKM(item.location.geo, state.guideSimulation.destination.geo)
                    : 0,
                })),
                state: {
                  ...state.guideSimulation.state,
                  simulationStatus: action.status,
                },
              },
            };
          case 'eliminating_candidate':
            return {
              ...state,
              guideSimulation: {
                ...state.guideSimulation,
                list: state.guideSimulation.list.map((item) => ({
                  ...item,
                  status:
                    (item.distance &&
                      (item.distance <= state.guideSimulation.state.currentRadius ? 'candidate' : 'eliminated')) ||
                    item.status,
                })),
                state: {
                  ...state.guideSimulation.state,
                  simulationStatus: action.status,
                  candidateList: state.guideSimulation.list.filter(
                    (item) => item.distance && item.distance <= state.guideSimulation.state.currentRadius,
                  ),
                },
              },
            };
          default:
            return state;
        }
      }
      return state;
    case 'SETTING_GUIDE_SIMULATION':
      return {
        ...state,
        guideSimulation: {
          ...state.guideSimulation,
          setting: {
            initialRadius: Number(action.setting.initialRadius),
            incrementalRadius: Number(action.setting.incrementalRadius),
            maximumRadius: Number(action.setting.maximumRadius),
            minimumCandidate: Number(action.setting.minimumCandidate),
            stepDelay: Number(action.setting.stepDelay),
          },
        },
      };
    case 'RESET_GUIDE_SIMULATION_V1':
      return {
        ...state,
        guideSimulationV1: {
          ...initialState.guideSimulationV1,
        },
      };
    case 'STOP_GUIDE_SIMULATION_V1':
      return {
        ...state,
        guideSimulationV1: {
          ...state.guideSimulationV1,
          destination: undefined,
          list: state.guideSimulationV1.list.map((item) => ({ ...item, status: 'idle', distance: undefined })),
          state: {
            ...initialState.guideSimulationV1.state,
            simulationStatus: 'idle',
            currentCenter: state.guideSimulationV1.state.currentCenter,
          },
        },
      };
    case 'REMOVE_GUIDE_SIMULATION_V1':
      return {
        ...state,
        guideSimulationV1: {
          ...state.guideSimulationV1,
          list: state.guideSimulationV1.list.filter((item) => item.number !== action.number),
        },
      };
    case 'ADD_GUIDE_SIMULATION_V1':
      return {
        ...state,
        guideSimulationV1: {
          ...state.guideSimulationV1,
          number: state.guideSimulationV1.number + 1,
          list: [
            ...state.guideSimulationV1.list,
            {
              ...action.guide,
              number: state.guideSimulationV1.number + 1,
            },
          ],
          state: {
            ...state.guideSimulationV1.state,
            currentCenter: action.guide.location.geo,
          },
        },
      };
    case 'DESTINATION_GUIDE_SIMULATION_V1':
      return {
        ...state,
        guideSimulationV1: {
          ...state.guideSimulationV1,
          list: state.guideSimulationV1.list.map((item) => ({
            ...item,
            status: 'in selection',
          })),
          destination: action.destination,
          state: {
            ...state.guideSimulationV1.state,
            simulationStatus: 'target_added',
            currentCenter: action.destination.geo,
          },
        },
      };
    case 'STATUS_GUIDE_SIMULATION_V1':
      if (state.guideSimulationV1.state.simulationStatus !== 'idle') {
        switch (action.status) {
          case 'measure_distance':
            return {
              ...state,
              guideSimulationV1: {
                ...state.guideSimulationV1,
                list: state.guideSimulationV1.list.map((item) => ({
                  ...item,
                  distance: state.guideSimulationV1.destination
                    ? distanceGeoInKM(item.location.geo, state.guideSimulationV1.destination.geo)
                    : 0,
                })),
                state: {
                  ...state.guideSimulationV1.state,
                  simulationStatus: action.status,
                },
              },
            };
          case 'done': {
            const nearest = state.guideSimulationV1.list.reduce(
              (prev, curr) => ((prev.distance || 0) < (curr.distance || 0) ? prev : curr),
              state.guideSimulationV1.list[0],
            );
            const isInRadius = (nearest.distance || 0) <= state.guideSimulationV1.setting.maximumRadius;
            return {
              ...state,
              guideSimulationV1: {
                ...state.guideSimulationV1,
                list: state.guideSimulationV1.list.map((item) => ({
                  ...item,
                  status: item.number === nearest.number && isInRadius ? 'selected' : 'eliminated',
                })),
                state: {
                  ...state.guideSimulationV1.state,
                  match: isInRadius ? nearest : undefined,
                  simulationStatus: action.status,
                },
              },
            };
          }
          default:
            return state;
        }
      }
      return state;
    case 'SETTING_GUIDE_SIMULATION_V1':
      return {
        ...state,
        guideSimulationV1: {
          ...state.guideSimulationV1,
          setting: {
            maximumRadius: Number(action.setting.maximumRadius),
            stepDelay: Number(action.setting.stepDelay),
          },
        },
      };
    default:
      return state;
  }
};
