/* eslint-disable no-param-reassign */
import FirebaseFirestoreTypes from '@firebase/firestore-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConversationChat, ConversationData, ConversationScheme } from '../../../../../schemas/chat';

export interface ChatState {
  inbox: {
    isReady: boolean;
    list: ConversationData[];
  };
  messages: {
    data?: ConversationData;
    isReady: boolean;
    list: ConversationChat[];
    total: number;
  };
}

const initialState: ChatState = {
  inbox: {
    list: [],
    isReady: false,
  },
  messages: {
    list: [],
    isReady: false,
    total: 0,
  },
};

const MAX_CHAT_DISPLAYED = 50;

export const parseConversation = (uid: string) => (
  doc: FirebaseFirestoreTypes.QueryDocumentSnapshot<ConversationScheme>,
) => {
  const conversation = doc.data();
  const senderIndex = conversation.recipients.indexOf(uid);
  const receiverIndex = senderIndex === 0 ? 1 : 0;

  const senderUser = conversation.users[conversation.recipients[senderIndex]];
  const receiverUser = conversation.users[conversation.recipients[receiverIndex]];

  const maps: ConversationData['maps'] = {
    [uid]: {
      type: 'sender',
      user: senderUser,
    },
    [conversation.recipients[receiverIndex]]: {
      type: 'receiver',
      user: receiverUser,
    },
  };

  if (!senderUser) return null;
  if (!receiverUser) return null;

  const last =
    conversation.chats && conversation.chats.length
      ? {
          photoURL: maps[conversation.chats[conversation.chats.length - 1].uid]?.user.photoURL,
          type: maps[conversation.chats[conversation.chats.length - 1].uid]?.type as any,
          text: conversation.chats[conversation.chats.length - 1].text,
          timestamp: conversation.chats[conversation.chats.length - 1].timestamp,
        }
      : undefined;

  const isFromAdmin = conversation.recipients[receiverIndex] === 'admin';

  return {
    key: doc.id,
    sortNo: Number(`${Number(isFromAdmin)}${conversation.updatedAt || conversation.createdAt}`),
    orderID: conversation.orderID,
    unread: (conversation.chats?.length || 0) - (maps[uid].user.read || 0),
    receiver: receiverUser,
    sender: senderUser,
    last,
    maps,
  } as ConversationData;
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setInbox: {
      reducer: (state, action: PayloadAction<ConversationData[]>) => {
        state.inbox.isReady = true;
        state.inbox.list = action.payload.sort((a, b) => b.sortNo - a.sortNo);
      },
      prepare: (uid: string, snapshot: FirebaseFirestoreTypes.QuerySnapshot<ConversationScheme>) => {
        const payload = snapshot.docs.map(parseConversation(uid)).filter(Boolean) as any;

        return {
          payload,
        };
      },
    },
    clearInbox: (state) => {
      state.inbox.isReady = false;
      state.inbox.list = [];
    },
    setMessages: {
      reducer: (state, action: PayloadAction<{ data?: ConversationData; list: ConversationChat[]; total: number }>) => {
        state.messages.isReady = true;
        state.messages.list = action.payload.list;
        state.messages.data = action.payload.data;
        state.messages.total = action.payload.total;

        state.inbox.isReady = false;
        state.inbox.list = [];
      },
      prepare: (uid: string, snapshot: FirebaseFirestoreTypes.QueryDocumentSnapshot<ConversationScheme>) => {
        const data = snapshot.data();
        return {
          payload: {
            data: parseConversation(uid)(snapshot) || undefined,
            list: data.chats?.slice(-MAX_CHAT_DISPLAYED)?.reverse() || [],
            total: data.chats?.length || 0,
          },
        };
      },
    },
    clearMessages: (state) => {
      state.messages.isReady = false;
      state.messages.list = [];
      state.messages.data = undefined;
    },
  },
});

export const { setInbox, clearInbox, setMessages, clearMessages } = chatSlice.actions;
export default chatSlice.reducer;
