import styled from 'styled-components';

export const Wrapper = styled.div<{ isFull?: boolean; size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* height: ${({ isFull }) => (isFull ? '100vh' : '100%')}; */
  position: ${({ isFull }) => (isFull ? 'absolute' : 'relative')};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex-direction: column;
  filter: grayscale(1);

  & img {
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
  }
`;
