import { SettingAction } from '../action/setting';
import { LangCode } from '../../ui/language';

export const initialState = {
  language: 'ID' as LangCode,
};

export type SettingState = typeof initialState;

export default (state = initialState, action: SettingAction): SettingState => {
  switch (action.type) {
    case 'SWITCH_LANGUAGE':
      return {
        ...state,
        language: action.code,
      };
    default:
      return state;
  }
};
