import { Geo } from '../../../schemas/match';

export const distanceGeoInKM = (loc1: Geo, loc2: Geo) => {
  if (loc1.lat === loc2.lat && loc1.lng === loc2.lng) return 0;

  const radlat1 = (Math.PI * loc1.lat) / 180;
  const radlat2 = (Math.PI * loc2.lat) / 180;
  const theta = loc1.lng - loc2.lng;
  const radtheta = (Math.PI * theta) / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) dist = 1;

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist *= 1.609344;

  return dist;
};

export const randomInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
