import { UserAction } from '../action/user';
// import UserSchema from '../../firebase/schemas/user';

const initialState = {
  isLoaded: false,
  isLoading: false,
  isLoggedIn: false,
  // currentUser: undefined as UserSchema | undefined,
};

export type UserState = typeof initialState;

export default (state = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case 'LOGGED_IN':
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        isLoggedIn: action.value,
      };
    case 'AUTH_LOADING':
      return { ...state, isLoading: true };

    case 'RESET_USER':
      return {
        ...initialState,
        isLoaded: state.isLoaded,
      };

    default:
      return state;
  }
};
