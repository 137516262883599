import styled, { keyframes, DefaultTheme } from 'styled-components';

export const Dimmer = styled.div<{ value: number; variant: 'black' | 'white'; isFull?: boolean }>`
  position: ${({ isFull }) => (isFull ? 'fixed' : 'absolute')};
  box-sizing: border-box;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  background-color: ${({ variant, value }) =>
    `${variant === 'black' ? '#000000' : '#ffffff'}${Math.round(value * 255).toString(16)}`};
  /* opacity: 0; */
  line-height: 1;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  transition: background-color 400ms linear;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  /* will-change: opacity; */
  z-index: ${({ isFull }) => (isFull ? 50 : 40)};
  visibility: visible !important;
  opacity: 1;
  border-radius: inherit !important;
  display: flex !important;
`;

export const rotation = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
`;

export const LoaderWrapper = styled.div<{
  size?: number;
}>`
  font-size: ${({ size }) => (size ? `${size}px` : 'auto')};
  & > * {
    vertical-align: middle;
    line-height: 1;
  }
`;

export const Spinner = styled.div<{
  size?: number;
  slow?: boolean;
  variant?: keyof DefaultTheme['variant'];
}>`
  display: inline-block;
  vertical-align: middle;
  margin: 0px;
  text-align: center;
  z-index: 40;

  box-sizing: border-box;
  min-width: 1.4em;
  min-height: 1.4em;
  font-size: ${({ size }) => (size ? `${size}px` : 'auto')};
  /* transform: translateY(-50%); */

  // padding-top: 1.25em;

  color: ${({ variant, theme }) => (variant ? theme.variant[variant].background : theme.colors.text.primary)};

  &:before {
    position: absolute;
    content: '';
    /* top: 0%; */
    /* left: 50%; */
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 0.1em solid
      ${({ variant, theme }) => (variant ? theme.variant[variant].background : theme.colors.text.strong)}20;
    width: 1.4em;
    height: 1.4em;
    margin: 0em 0em 0em -0.75em;
    box-sizing: border-box;
  }

  /* Active Shape */

  &:after {
    position: absolute;
    content: '';
    /* top: 0%; */
    /* left: 50%; */
    width: 100%;
    height: 100%;
    animation: ${rotation};
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: ${({ slow }) => (slow ? 'reverse' : 'normal')};
    animation-duration: ${({ slow }) => (slow ? 1.2 : 0.6)}s;
    border-radius: 100%;
    border-color: ${({ variant, theme }) => (variant ? theme.variant[variant].background : theme.colors.text.primary)}
      transparent transparent;
    border-style: solid;
    border-width: 0.1em;
    box-shadow: 0px 0px 0px 1px transparent;
    width: 1.4em;
    height: 1.4em;
    margin: 0em 0em 0em -0.75em;
    box-sizing: border-box;
  }
`;

export const SpinnerMiddle = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const flash = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50%,
  100% {
    opacity: 0.5;
    transform: scale(0.75);
  }
`;

const grow = keyframes`
  to{
    transform: translateX(-50%) scale(0.4);
    opacity: 0.2;
  }
`;

export const ThreeDots = styled.div<{ size: number; variant?: keyof DefaultTheme['variant'] }>`
  & > div {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: 50%;
    background-color: ${({ variant, theme }) =>
      (variant && theme.variant[variant].background) || theme.colors ? theme.colors.text.primary : '#333'};

    margin: 0px ${({ size }) => size / 4}px;

    &:nth-of-type(1) {
      animation: ${flash} 0.5s infinite linear alternate;
      animation-delay: 0s;
    }

    &:nth-of-type(2) {
      animation: ${flash} 0.5s infinite linear alternate;
      animation-delay: 0.25s;
    }
    &:nth-of-type(3) {
      animation: ${flash} 0.5s infinite linear alternate;
      animation-delay: 0.5s;
    }
  }
`;
